<template>
  <div>
    <div class="box">
      <el-page-header
        @back="$router.go(-1)"
        :content="$t('tPassDetail')"
      ></el-page-header>
    </div>
    <el-card class="content mt20 bbb">
      <el-form
        label-position="left"
        :model="ruleForm"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('pass.create_time')" prop="create_time">
          <div>{{ ruleForm.create_time }}</div>
        </el-form-item>
        <el-form-item :label="$t('pass.status')" prop="status">
          <div>{{ status(ruleForm.status) }}</div>
        </el-form-item>
        <el-form-item :label="$t('pass.username')" prop="username"
          >
          <div>{{ ruleForm.username }}</div>
        </el-form-item>
        <el-form-item :label="$t('pass.subject')" prop="subject"
          >
          <div>{{ ruleForm.subject }}</div>
        </el-form-item>
        <el-form-item :label="$t('pass.row1')" prop="row1"
          >
          <div :class="{'fwB' : ruleForm.content[0].size === 1}">{{ ruleForm.content[0].row }}</div>
        </el-form-item>
        <el-form-item :label="$t('pass.row2')" prop="row2"
          >
          <div :class="{'fwB' : ruleForm.content[1].size === 1}">{{ ruleForm.content[1].row }}</div>
        </el-form-item>
        <el-form-item :label="$t('pass.row3')" prop="row3"
          >
          <div :class="{'fwB' : ruleForm.content[2].size === 1}">{{ ruleForm.content[2].row }}</div>
        </el-form-item>
        <el-form-item :label="$t('pass.url')" prop="pass_url"
          >
          <div>{{ ruleForm.pass_url }}</div>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import { passDetail } from "@/api/index";
export default {
  name: "passDetail",
  data() {
    return {
      ruleForm: {
        create_time:'',
        status:0,
        username:'',
        subject:'',
        content:[
            {row:'',size:0},
            {row:'',size:0},
            {row:'',size:0},
        ]
      },
    };
  },
  computed: {
    id() {
      return Number.isNaN(Number(this.$route.query.id))
        ? undefined
        : Number(this.$route.query.id);
    }
  },
  methods: {
      status(status) {
      switch (status) {
        case 0:
          return this.$t("pass.status0");
        case 1:
          return this.$t("pass.status1");
      }
    },
    async get_info() {
      const information = await passDetail(this.id);
      this.ruleForm = { ...information };
    },
  },
  async created() {
    if (this.id === undefined) return;
    this.get_info();
  },
};
</script>
<style land="scss" scoped>
.el-date-editor {
  margin-right: 10px;
}
.bbb {
  position: relative;
  padding-bottom: 50px;
}
.bbb .btn {
  position: absolute;
  bottom: 10px;
  right: 20px;
}
</style>
